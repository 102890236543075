import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { search_success } from "../../actions/search";
import SelectDropdown from "../../components/common/SelectDropdown";
import Layout from "../../components/global/layout"
import ApiClient from "../../methods/api/apiClient";
import statusModel from "../../models/status.model";
import Pagination from "react-pagination-js";
import { toast } from "react-toastify";
import moment from "moment";

const FeaturesListing = () => {
    const user = useSelector((state) => state.user)
    const dispatch = useDispatch()
    const searchState = useSelector((state) => state.search)
    const [filter, setfilter] = useState({ page: 1, count: 20, status: '', search: '' })
    const [data, setdata] = useState()
    const [total, settotal] = useState(0)
    const [loading, setloading] = useState(false)
    const [deleteId, setdeleteId] = useState()
    const history = useHistory()

    useEffect(() => {
        getdata()
    }, [])

    const getdata = (p = {}) => {
        // let filt = { ...filter, ...p }
        // setloading(true)
        // ApiClient.get(`subscription-plan/all`, filt).then(res => {
        //     if (res.success) {
        //         setdata(res.data.data)
        //         settotal(res.data.total)
        //     }
        //     setloading(false)
        // })
    }

    useEffect(() => {
        if (user && user.loggedIn) {
            setfilter({ ...filter, search: searchState.data });
            getdata({ search: searchState.data, page: 1 });
        }
    }, [searchState]);

    const handleFilters = (p = {}) => {
        setfilter({ ...filter, ...p })
        getdata(p)
    }

    const clearAllFilters = () => {
        let p = { page: 1, count: 20, search: '', status: '' }
        setfilter({ ...filter, ...p })
        dispatch(search_success(''))
        getdata(p)
    }

    const statusChange = (item) => {
        // let status = 'active';
        // if (item.status == 'active') status = 'deactive';
        // if (window.confirm(`Do you want to ${status == 'active' ? 'Activate' : 'Deactivate'} this`)) {
        //     setloading(true);
        //     ApiClient.put(`change/status?model=&status=${status}&id=${item?._id}`).then((res) => {
        //         if (res.success) {
        //             toast.success(res.message)
        //             getdata();
        //         }
        //         setloading(false);
        //     }
        //     )
        // }
    }

    // const handleAction = (id, key) => {
    //     if (key == 'edit') {
    //         history.push(`/feature/edit/${id}`)
    //     } else {
    //         ApiClient.delete(``).then(res => {
    //             if (res.success) {
    //                 toast.success(res.message)
    //                 getdata()
    //                 document.getElementById('CloseDeleteModal').click()
    //             }
    //         })
    //     }
    // }

    const pageChange = (e) => {
        setfilter({ ...filter, page: e });
        getdata({ page: e });
    }

    return (
        <>
            <Layout>
                <div className="d-flex justify-content-between">
                    <h1>Features</h1>
                    <div className="d-flex">
                        <Link to={`/feature/add`} className="btn btn-primary h-75">Add Feature</Link>
                        <span className="mx-2">
                            <SelectDropdown
                                isSingle={true}
                                id="statusDropdown"
                                displayValue="name"
                                placeholder="Select Status"
                                className="mr-2"
                                intialValue={filter.status}
                                result={e => handleFilters({ status: e.value })}
                                options={statusModel.list}
                            />
                        </span>
                        {filter?.status || filter?.search ?
                            <button className="btn btn-secondary ml-2 h-75" onClick={e => clearAllFilters()}>Reset</button>
                            : null}
                    </div>
                </div>
                {!loading ?
                    <table className="table mt-2">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Date</th>
                                <th scope="col">Status</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        {loading ? null :
                            <tbody>
                                {data && data.map(item => {
                                    return <tr>
                                        <td scope="row" className="pointer text-capitalize">{item?.name}</td>
                                        <td>{moment(item?.createdAt).format('DD MMM YYYY')}</td>
                                        <td>
                                            <div className={`user_hours ${item.status}`} onClick={() => statusChange(item)}>
                                                <span className='text-capitalize pointer'>
                                                    {item.status == 'deactive' ? 'inactive' : 'active'}
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="action_icons">
                                                <a className='edit_icon pointer' title="Edit" onClick={e => handleAction(item?._id, 'edit')}>
                                                    <i class="material-icons edit" title="Edit">edit</i>
                                                </a>
                                                <span className='edit_icon pointer' onClick={e => setdeleteId(item?._id)} data-toggle="modal" data-target="#exampleModal">
                                                    <i class="material-icons delete text-danger" title='Delete'> delete</i>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        }
                    </table>
                    : null}
                {!loading && total == 0 ? <div className="py-3 text-center">No Data</div> : <></>}
                {
                    !loading && total > filter.count ? <div className='paginationWrapper'>
                        <span>Show {filter.count} from {total} records</span>
                        <Pagination
                            currentPage={filter.page}
                            totalSize={total}
                            sizePerPage={filter.count}
                            changeCurrentPage={pageChange}
                        />
                    </div> : <></>
                }
                {loading ? <div className="text-center py-4">
                    <img src="/assets/img/loader.gif" className="pageLoader" />
                </div> : <></>}
            </Layout>

            {/* Delete Modal */}
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-body">
                            <button type="button" class="close" id="CloseDeleteModal" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div className='text-center mt-4'>
                                <p>Are you sure you want to delete this?</p>
                                <button type="button" class="btn btn-danger" onClick={e => handleAction(deleteId, 'delete')}>Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FeaturesListing