import React from 'react';
import { ToastsContainer,ToastsStore,ToastsContainerPosition } from 'react-toasts';
import { PersistGate } from 'redux-persist/es/integration/react';
import "react-datepicker/dist/react-datepicker.css";
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { BrowserRouter as Router,Switch,Route } from 'react-router-dom';
import configureStore from './config';
import { createBrowserHistory } from 'history';
import { Auth } from './methods/auth';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Forgotpassword from './pages/Forgotpassword';
import Resetpassword from './pages/Resetpassword';
import Profile from './pages/Profile'
import Settings from './pages/Settings';
import './scss/main.scss';
import "react-pagination-js/dist/styles.css"; // import css
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-phone-input-2/lib/style.css'
import LogoSetting from './pages/Setting/index';
import Users from './pages/Users';
import AddEditUser from './pages/Users/AddEditUser';
import UserDetail from './pages/Users/Profiledetail';
import QrcodeType from './pages/QrCodeType';
import AddEditQrCodeType from './pages/QrCodeType/AddEditQrCodeType';
import Bloglisting from './pages/Blog/Bloglisting';
import AddEditBlog from './pages/Blog/AddEditBlog';
import BlogDetail from './pages/Blog/BlogDetail';
import LogoListing from './pages/Logo/LogoListing';
import LogoDetail from './pages/Logo/LogoDetail';
import AddEditLogo from './pages/Logo/AddEditLogo';
import FAQListing from './pages/FAQ/FAQListing';
import FAQDetail from './pages/FAQ/FAQDetail';
import AddEditFAQ from './pages/FAQ/AddEditFAQ';
import PlanListing from './pages/Plan/PlanListing';
import AddEditPlan from './pages/Plan/AddEditPlan';
import PlanDetails from './pages/Plan/PlanDetails';
import Support from './pages/Support';
import SupportDetail from './pages/Support/SupportDetail';
import FeaturesListing from './pages/Features/FeaturesListing';
import AddEditFeatures from './pages/Features/AddEditFeatures';
import TermsOfUse from './pages/TermsOfUse';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CookiePolicy from './pages/CookiePolicy';
import WithdrawRequests from './pages/WithdrawRequests';
import FAQCategoryListing from './pages/FAQCategory/FAQCategoryListing';
import AddEditFAQCategory from './pages/FAQCategory/AddEditFAQCategory';

export const history = createBrowserHistory();
/************ store configration *********/
const { persistor, store } = configureStore(history);

export default () => {
    return (<>
        <Provider store={store}>
            <PersistGate loading={'loading ...'} persistor={persistor}>
                <ConnectedRouter history={history}>
                    <Router>
                        <Switch>
                            <Route exact={true} path="/dashboard" store={store} requireAuth={Auth} component={Dashboard} />
                            <Route exact={true} path="/setting" store={store} requireAuth={Auth} component={LogoSetting} />
                            <Route exact={true} path="/profile" store={store} requireAuth={Auth} component={Profile} />
                            <Route exact={true} path="/profile/:tab" store={store} requireAuth={Auth} component={Settings} />
                            <Route exact={true} path="/login" store={store} requireAuth={Auth} component={Login} />
                            <Route exact={true} path="/forgotpassword" store={store} requireAuth={Auth} component={Forgotpassword} />
                            <Route exact={true} path="/resetpassword" store={store} requireAuth={Auth} component={Resetpassword} />

                            {/* Users */}
                            <Route exact={true} path="/users" store={store} requireAuth={Auth} component={Users} />
                            <Route exact={true} path="/users/add" store={store} requireAuth={Auth} component={AddEditUser} />
                            <Route exact={true} path="/users/edit/:id" store={store} requireAuth={Auth} component={AddEditUser} />
                            <Route exact={true} path="/usersdetail/:id" store={store} requireAuth={Auth} component={UserDetail} />

                            {/* Qr Code Type */}
                            <Route exact={true} path="/qrtypes" store={store} requireAuth={Auth} component={QrcodeType} />
                            <Route exact={true} path="/qrtypes/add" store={store} requireAuth={Auth} component={AddEditQrCodeType} />
                            <Route exact={true} path="/qrtypes/edit/:id" store={store} requireAuth={Auth} component={AddEditQrCodeType} />

                            {/* Blog */}
                            <Route exact={true} path="/blog" store={store} requireAuth={Auth} component={Bloglisting} />
                            <Route exact={true} path="/blog/detail/:id" store={store} requireAuth={Auth} component={BlogDetail} />
                            <Route exact={true} path="/blog/add" store={store} requireAuth={Auth} component={AddEditBlog} />
                            <Route exact={true} path="/blog/edit/:id" store={store} requireAuth={Auth} component={AddEditBlog} />

                            {/* Logo */}
                            <Route exact={true} path="/logo" store={store} requireAuth={Auth} component={LogoListing} />
                            <Route exact={true} path="/logo/detail/:id" store={store} requireAuth={Auth} component={LogoDetail} />
                            <Route exact={true} path="/logo/add" store={store} requireAuth={Auth} component={AddEditLogo} />
                            <Route exact={true} path="/logo/edit/:id" store={store} requireAuth={Auth} component={AddEditLogo} />

                            {/* FAQ Categories */}
                            <Route exact={true} path="/categories" store={store} requireAuth={Auth} component={FAQCategoryListing} />
                            <Route exact={true} path="/categories/add" store={store} requireAuth={Auth} component={AddEditFAQCategory} />
                            <Route exact={true} path="/categories/edit/:id" store={store} requireAuth={Auth} component={AddEditFAQCategory} />

                            {/* FAQ */}
                            <Route exact={true} path="/faq" store={store} requireAuth={Auth} component={FAQListing} />
                            <Route exact={true} path="/faq/detail/:id" store={store} requireAuth={Auth} component={FAQDetail} />
                            <Route exact={true} path="/faq/add" store={store} requireAuth={Auth} component={AddEditFAQ} />
                            <Route exact={true} path="/faq/edit/:id" store={store} requireAuth={Auth} component={AddEditFAQ} />

                            {/* Features */}
                            <Route exact={true} path="/feature" store={store} requireAuth={Auth} component={FeaturesListing} />
                            <Route exact={true} path="/feature/add" store={store} requireAuth={Auth} component={AddEditFeatures} />
                            <Route exact={true} path="/feature/edit/:id" store={store} requireAuth={Auth} component={AddEditFeatures} />

                            {/* Plan Managemnet */}
                            <Route exact={true} path="/plan" store={store} requireAuth={Auth} component={PlanListing} />
                            <Route exact={true} path="/plan/add" store={store} requireAuth={Auth} component={AddEditPlan} />
                            <Route exact={true} path="/plan/edit/:id" store={store} requireAuth={Auth} component={AddEditPlan} />
                            <Route exact={true} path="/plan/detail/:id" store={store} requireAuth={Auth} component={PlanDetails} />

                            {/* Support */}
                            <Route exact={true} path="/support" store={store} requireAuth={Auth} component={Support} />
                            <Route exact={true} path="/support/detail/:id" store={store} requireAuth={Auth} component={SupportDetail} />

                            {/* Withdraw Requests */}
                            <Route exact={true} path="/withdrawrequests" store={store} requireAuth={Auth} component={WithdrawRequests} />

                            {/* Terms Of Use */}
                            <Route exact={true} path="/termsofuse" store={store} requireAuth={Auth} component={TermsOfUse} />

                            {/* Privacy Policy */}
                            <Route exact={true} path="/privacypolicy" store={store} requireAuth={Auth} component={PrivacyPolicy} />

                            {/* Cookie Policy */}
                            <Route exact={true} path="/cookiepolicy" store={store} requireAuth={Auth} component={CookiePolicy} />

                            <Route exact path="/"><Redirect to="/login" /></Route>
                        </Switch>
                    </Router>
                </ConnectedRouter>
            </PersistGate>
        </Provider>
        <div id="loader" className="loaderDiv d-none">
            <div>
                <img src="/assets/img/loader.gif" alt="logo" className="loaderlogo" />
            </div>
        </div>
        <ToastsContainer
            position={ToastsContainerPosition.TOP_RIGHT}
            store={ToastsStore}
        />
    </>
    );
};
