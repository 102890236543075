
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import methodModel from '../../methods/methods';
import './style.scss';

const Resetpassword = () => {
    const user = useSelector(state => state.user)
    const [form, setForm] = useState({ confirmPassword: '', newPassword: '', code: '' });
    const [submitted, setSubmitted] = useState(false)
    const [eyes, setEyes] = useState({ password: false, confirmPassword: false });
    const history = useHistory();

    const formValidation = [
        { key: 'confirmPassword', minLength: 8, confirmMatch: ['confirmPassword', 'newPassword'] },
        { key: 'newPassword', minLength: 8 },
    ]

    useEffect(() => {
        if (localStorage.getItem('token')) {
            history.push('/dashboard')
        }
    }, [])

    const getError = (key) => {
        return methodModel.getError(key, form, formValidation)
    }

    useEffect(() => {
        let prm = {
            // id: methodModel.getPrams('id'),
            code: methodModel.getPrams('code'),
        }
        setForm({ ...form, ...prm })
    }, [])

    const hendleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true)
        let invalid = methodModel.getFormError(formValidation, form)
        if (invalid) return
        loader(true)
        let payload = {
            ...form
        }
        ApiClient.put('reset/password', payload).then(res => {
            if (res.success) {
                setTimeout(() => {
                    toast.success(res.message)
                }, 100);
                history.push('/login');
            }
            loader(false)
        })
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-md-4 px-0">
                        <div className='banner_img'>
                            <div className='logo_img'>
                                {/* <Link to="/"> <img src="/assets/img/logo.png" className='logo' /></Link> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-8 p-0">
                        <div className='right_side'>
                            <form className="centerLogin" onSubmit={hendleSubmit}>
                                <div className="text-center mb-2">
                                    <h3 className="text-left lgtext">Reset Password</h3>
                                </div>
                                <div className="mb-3">
                                    {/* <div className="mb-3">
                                        <div className="inputWrapper">
                                            <input
                                                type='text'
                                                className="form-control mb-0 bginput"
                                                value={form.code}
                                                onChange={e => setForm({ ...form, code: e.target.value })}
                                                placeholder="Enter Verification Code"
                                                required
                                            />
                                        </div>
                                    </div> */}
                                    <div className="mb-3">
                                        <div className="inputWrapper">
                                            <input
                                                type={eyes.password ? 'text' : 'password'}
                                                className="form-control mb-0 bginput"
                                                value={form.newPassword}
                                                onChange={e => setForm({ ...form, newPassword: e.target.value })}
                                                placeholder="Create new password"
                                                required
                                            />
                                            <i className={eyes.password ? 'fa fa-eye' : 'fa fa-eye-slash'} onClick={() => setEyes({ ...eyes, password: !eyes.password })}></i>
                                        </div>
                                        {submitted && getError('newPassword').invalid ? <div className="invalid-feedback d-block">Min Length must be 8 characters long</div> : <></>}
                                    </div>
                                    <div className="inputWrapper">
                                        <div className="inputWrapper">
                                            <input
                                                type={eyes.confirmPassword ? 'text' : 'password'}
                                                className="form-control mb-0 bginput"
                                                value={form.confirmPassword}
                                                onChange={e => setForm({ ...form, confirmPassword: e.target.value })}
                                                placeholder="Confirm new password"
                                                required
                                            />
                                            <i className={eyes.confirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'} onClick={() => setEyes({ ...eyes, confirmPassword: !eyes.confirmPassword })}></i>
                                        </div>
                                        {submitted && getError('confirmPassword').err.confirmMatch ? <div className="invalid-feedback d-block">Confirm Password is not matched with New Password</div> : <></>}
                                    </div>
                                </div>
                                <div className="buttons">
                                    <button type="submit" className="btn btn-primary loginclass mb-4">
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <ToastContainer position="top-right" />
            </div>
        </>
    );
};

export default Resetpassword;
