import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";

const AddEditFeatures=()=>{
    const {id} = useParams
    const [form, setform] = useState({ name: '' })
    const history = useHistory()

    const handlSubmit=(e)=>{
        e.preventDefault()
        let payload = {
            name: form?.name,
        }
        // if(!id){
        //     ApiClient.post(``).then(res=>{
        //         if(res.success){
        //             setTimeout(() => {
        //                 toast.success(res.message)
        //             }, 100);
        //             history.push('/feature')
        //         }
        //     })
        // }else{
        //     payload = {
        //         ...payload,
        //         id: ''
        //     }
        //     ApiClient.put(``).then(res=>{
        //         if(res.success){
        //             setTimeout(() => {
        //                 toast.success(res.message)
        //             }, 100);
        //             history.push('/feature')
        //         }
        //     })
        // }
    }

    useEffect(()=>{
        // if(id) getDetail()
    },[])

    const getDetail=()=>{
        ApiClient.get(``).then(res=>{
            if(res.success){
                setform(res?.data)
            }
        })
    }

    return(
        <>
            <Layout>
                <h1>{!id ? 'Add' : 'Edit'} Feature</h1>
                <form onSubmit={handlSubmit}>
                    <div className="row mt-4">
                        <div className="col-md-12">
                            <label className="form-label">Name</label>
                            <input type="text" value={form?.name} onChange={e=> setform({ ...form, name: e.target.value })} className="form-control" placeholder="Name" />
                        </div> 
                        <div className="col-md-12 text-right mt-3">
                            <button className="btn btn-secondary mr-2" onClick={e=>{ history.goBack() }} >Back</button>
                            <button className="btn btn-primary ml-2" >{!id?'Save':'Update'}</button>
                        </div>
                    </div>
                </form>
            </Layout>
        </>
    )
}

export default AddEditFeatures