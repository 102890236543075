import React, { useState } from 'react';
import Layout from '../../components/global/layout';

import 'react-phone-input-2/lib/style.css';
import { NavLink, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';

import methodModel from '../../methods/methods';
import ApiClient from '../../methods/api/apiClient';
import { userType } from '../../models/type.model';


const Html = ({ handleSubmit }) => {
  const History = useHistory()
  const review = JSON.parse(localStorage.getItem('review'))
  let logo = review?.logo;
  console.log(review)
  const param = useParams()
  console.log(param.id)
  const[loading,setloading]=useState(false)
  const [data, setdata] = useState({});
  const [form, setForm] = useState({ ...userType });
  const [img, setimg] = useState(review?.logo);
  const UploadImage = (e) => {
    let image = e.target.files;
    let file = image.item(0);
    console.log(file);
    setloading(true)
    ApiClient.postFormData('api/upload/image?modelName=logo', {
      file: file,
      modelName: 'logo',
      
    }).then((res) => {
      if (res.fileName) {
        let image = res.fileName;
        setloading(false)
          setForm({ ...form, logo: image, baseImg: '' })
        setimg(image);
       if(image){
        logo=''
       }
        setForm({ ...form, logo: image });
        console.log(data);
        console.log(res);
      }
    });
  };

const UpdateReview = (e)=>{
  
  ApiClient.put('api/review/platform',{platform:review.platform,logo:img,id:param.id}).then((res)=>{
    console.log(res)
    if(res.success){
History.push('/reviews')
    }
  })

}




  return (
    <>
      <Layout>
      
          <div className="pprofile1">
        
            <h3 className="ViewUser">
              {form && form.id ? 'Edit' : 'Upload'} Logo
            </h3>
            <div className="form-row">
              <div className="col-md-6 mb-3">
              
              </div>

              <div className="col-md-6 mb-3"></div>
              <div className="col-md-6 mb-3">
                <div className="maininutcls col-md-6">
                    {
loading? <label className='mr-5 ' style={{color:'green'}}>Uploading...</label>:<label className="profileImageLabel">
                      <img
                      src={methodModel.userImg(img)}
                      className="profileImage ml-2"
                      />
                  </label>
                    }
                    
                  <div className="profile_btn">
                    <div>
                      
                      <label className="btn btn-primary mt-3 edit ml-3">
                        
                        <input
                          id="bannerImage"
                          type="file"
                          className="d-none"
                          accept="image/*"
                          onChange={(e) => {
                            UploadImage(e);
                          }}
                        />
                        Upload Image
                      </label>{
img? <label className="btn btn-primary delete ml-3" onClick={()=>{
  setimg('')
}}>Remove Image</label>:null
                      }
                    </div>
                  </div>
                </div>
              </div>
                  
            </div>

            <div className="text-right">
              <NavLink to={'/reviews'}>
                <button
                  type="button"
                  className="btn btn-secondary discard mr-2"
                >
                  Back
                </button>
              </NavLink>
              <button onClick={UpdateReview} type="submit" className="btn btn-primary">
                Save
              </button>
            </div>
          </div>
       
      </Layout>
    </>
  );
};

export default Html;
